const socialLinks = {
  instagram: {
    link: "https://instagram.com/134_lounge",
    ariaLabel: "Instagram",
  },
  facebook: {
    link: "https://www.facebook.com/134loungeplzen",
    ariaLabel: "Facebook",
  },
};

export default socialLinks;
